.background {
  background-image: url("/GettyImages-1044660554.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
}
.container-fluid {
  max-width: 720px;
}
.card-image {
  max-width: 300px;
  max-height: 40px;
}
.card {
  cursor: pointer;
}
